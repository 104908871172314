import { useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import slugify from 'slugify';
import BeansLeft from '../atoms/BeansLeft';
import BeansRight from '../atoms/BeansRight';
import FaqQuestion from '../molecules/FaqQuestion';

interface FaqContentProps {
  topics: Array<{
    title: string;
    question: Array<{ question: string; answer: string }>;
  }>;
}

const FaqContent = ({ topics }: FaqContentProps) => {
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 10000], [1500, 0]);
  const y2 = useTransform(scrollY, [0, 3000], [-200, -800]);

  return (
    <section className="relative py-24">
      <BeansLeft className="absolute left-0 -z-1" style={{ y }} />
      <BeansRight className="absolute right-0 -z-1" style={{ y: y2 }} />
      <div className="container">
        <h2 className="mb-12 text-center u-h2">FAQ</h2>
        <div className="space-y-12">
          {topics.map((topic) => (
            <div
              key={topic.title}
              id={slugify(topic.title)}
              className="max-w-3xl mx-auto"
            >
              <h3 className="mb-6 u-h3">{topic.title}</h3>
              <div className="space-y-6">
                {topic.question?.map((question) => (
                  <FaqQuestion
                    key={question.question}
                    question={question.question}
                    answer={question.answer}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqContent;
