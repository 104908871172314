import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const PlusIcon = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  >
    <path
      fill="#091F40"
      d="M8 0c-.59 0-1.067.477-1.067 1.067v5.866H1.067a1.066 1.066 0 100 2.134h5.866v5.866a1.066 1.066 0 102.134 0V9.067h5.866a1.066 1.066 0 100-2.134H9.067V1.067C9.067.477 8.59 0 8 0z"
    ></path>
  </motion.svg>
);

export default PlusIcon;
