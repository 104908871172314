import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const MinusIcon = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="2"
    fill="none"
    viewBox="0 0 16 2"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  >
    <rect width="16" height="2" fill="#091F40" rx="1"></rect>
  </motion.svg>
);

export default MinusIcon;
