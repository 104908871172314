import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import FaqContent from '../components/organisms/FaqContent';
import FaqHeroSection from '../components/organisms/FaqHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';

const Faqs = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <FaqHeroSection
          {...page.faqs.faqhero}
          topics={page.faqs.topics.map((topic) => topic.title)}
        />
        <FaqContent topics={page.faqs.topics} />

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query FaqsPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      faqs {
        faqhero {
          heading
          body
        }
        topics {
          title
          question {
            question
            answer
          }
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Faqs;
