import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import MinusIcon from '../atoms/MinusIcon';
import PlusIcon from '../atoms/PlusIcon';

interface FaqQuestionProps {
  question: string;
  answer: string;
}

const FaqQuestion = ({ question, answer }: FaqQuestionProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="p-4 overflow-hidden rounded bg-linen text-navy-blue">
      <button
        type="button"
        className="relative block w-full pr-10 text-lg font-bold leading-6 tracking-wide text-left text-navy-blue"
        onClick={() => setOpen(!isOpen)}
      >
        <div>{question}</div>
        <div className="absolute w-4 h-4 top-1 right-1">
          <AnimatePresence>
            {isOpen ? (
              <MinusIcon key="minus" className="absolute right-0 top-[7px]" />
            ) : (
              <PlusIcon key="plus" className="absolute top-0 right-0" />
            )}
          </AnimatePresence>
        </div>
      </button>
      <div className="pr-10 overflow-hidden">
        <motion.div
          className="leading-6 u-faq-prose"
          dangerouslySetInnerHTML={{ __html: answer }}
          animate={{ height: isOpen ? 'auto' : 0 }}
          transition={{ type: 'ease' }}
        />
      </div>
    </div>
  );
};

export default FaqQuestion;
